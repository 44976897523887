import React from 'react';
import PropTypes from 'prop-types';
// import Cookies from 'js-cookie';
import Helmet from 'react-helmet';

import { Footer } from './Footer';
// import { Header } from './Header';
// import { Header } from './HeaderNew';
// import CookiesAlert from './CookiesAlert';
import Header from './Header2';

const Layout = ({ children }) => {
  // const [showCookiesAlert, setShowCookiesAlert] = useState(false);

  // useEffect(() => {
  //   const cookie = Cookies.get('_cad');

  //   if (!cookie) {
  //     // FIXME: Fix cookie popup
  //     // setShowCookiesAlert(true);
  //   }
  // }, []);

  // const handleCookiesAlertDismiss = () => {
  //   Cookies.set('_cad', true, { expires: 30 });
  //   setShowCookiesAlert(false);
  // };
  return (
    <>
      <Helmet bodyAttributes={{ class: 'font-sans bg-white' }} />
      <Header />
      {/* {showCookiesAlert && (
        <CookiesAlert onDismiss={handleCookiesAlertDismiss} />
      )} */}
      <main className="leading-normal tracking-normal text-white">
        {children}
      </main>
      <Footer />
    </>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Layout;
