import React from 'react';
import PropTypes from 'prop-types';

const LogoGlyph = ({ color, mono, ...props }) => (
  <svg
    width="816px"
    height="702px"
    viewBox="0 0 816 702"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>TAGinstall-logo</title>
    <defs>
      <linearGradient
        x1="1.44424438%"
        y1="27.8699585%"
        x2="100%"
        y2="72.1300415%"
        id="linearGradient-1"
      >
        <stop stopColor="#17EAD9" offset="0%" />
        <stop stopColor="#6078EA" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="-3.14128593%"
        y1="24.5900398%"
        x2="166.68467%"
        y2="142.429949%"
        id="linearGradient-2"
      >
        <stop stopColor="#17EAD9" offset="0%" />
        <stop stopColor="#6078EA" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Logo-SVG-Google" transform="translate(-104.000000, -151.000000)">
        <g id="Group" transform="translate(104.000000, 151.000000)">
          <path
            d="M721.698405,187.571429 C750.168621,187.571429 773.248292,210.60318 773.248292,239.014286 L773.248292,650.557143 C773.248292,678.968248 750.168621,702 721.698405,702 L257.749431,702 L253.487072,701.965541 C113.101924,699.694135 0,585.420686 0,444.785714 C0,304.150742 113.101924,189.877293 253.487072,187.605888 L257.749431,187.571429 L721.698405,187.571429 Z M257.749431,377.91 C220.73815,377.91 190.734579,407.851277 190.734579,444.785714 C190.734579,481.720151 220.73815,511.661429 257.749431,511.661429 C294.760711,511.661429 324.764282,481.720151 324.764282,444.785714 C324.764282,407.851277 294.760711,377.91 257.749431,377.91 Z"
            id="Shape"
            fill="url(#linearGradient-1)"
          />
          <path
            d="M553.926136,5.61583419 C579.282116,-7.27780454 610.310537,2.78374641 623.230054,28.0889372 L810.372894,394.641747 C823.292412,419.946937 813.210666,450.913207 787.854686,463.806846 L374.655119,673.920829 L370.843332,675.820481 C244.781431,737.375246 92.0871374,686.816008 28.1355277,561.555314 C-35.816082,436.294619 12.949994,283.291807 136.946121,217.690853 L140.726568,215.729817 L553.926136,5.61583419 Z M227.280132,385.260492 C194.317358,402.022222 181.211089,442.278373 198.006461,475.175121 C214.801833,508.071869 255.138781,521.151885 288.101555,504.390155 C321.064329,487.628424 334.170597,447.372274 317.375225,414.475526 C300.579853,381.578778 260.242906,368.498761 227.280132,385.260492 Z"
            id="Shape"
            fill="url(#linearGradient-2)"
            opacity="0.5"
          />
        </g>
      </g>
    </g>
  </svg>
);

LogoGlyph.propTypes = {
  color: PropTypes.string,
  mono: PropTypes.bool,
};

LogoGlyph.defaultProps = {
  color: '#000000',
  mono: false,
};

export default LogoGlyph;
